import React, { FC } from 'react';
import { PageProps } from 'gatsby';

import { useSiteMetadata } from '~/hooks';

const IFrameReWrite: FC<
  Pick<PageProps, 'location'> & { baseDomain: string }
> = ({ location, baseDomain }) => {
  const search = new URLSearchParams(location.search);
  const url = `//${baseDomain}/?${search.toString()}`;
  return (
    <iframe
      src={url}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        border: 'none',
        padding: 0,
        margin: 0,
      }}
      height="100%"
      width="100%"
    />
  );
};

const GamesPage: FC<PageProps> = ({ location }) => {
  const { config } = useSiteMetadata();
  return (
    <IFrameReWrite baseDomain={config.mfeDomains.games} location={location} />
  );
};

export default GamesPage;
